<template>
  <div>
    <q-layout view="hHh Lpr lff">
      <q-header elevated class="glossy">
        <q-toolbar class="bg-white">
          <div style="flex: 1 1 100%">
            <div style="max-width:1000px; margin: 0 auto;">
              <img src="/img/intouch.png" height="75" style="padding: 13px; padding-bottom: 6px" />
            </div>
          </div>
          <q-separator vertical spaced dark />
          <q-space />
        </q-toolbar>
      </q-header>
      <q-page-container>
        <q-page class="items-center flex">
          <div id="wizard" v-if="supported">
            <div class="q-pa-md q-gutter-sm text-h4">
              Welcome to Panacea™ InTouch
            </div>

            <div v-if="index == 0">
              <div class="q-gutter-sm">
                <p>
                  Panacea™ InTouch brings hospitalized patients and their
                  relatives, friends and beloved ones closer, using the best
                  that technology can offer to provide easy and immediate video
                  and voice communication, without requiring user account or
                  registration.
                </p>
                <p>
                  In order to continue, we need permissions to use your camera
                  and microphone.
                </p>
              </div>

              <div style="margin:10px">
                <q-checkbox v-model="agreed">
                  I agree with the
                  <a href="javascript:void(0)" @click="showTerms = true">Terms of Use</a>
                </q-checkbox>
              </div>
              <div>
                <q-btn :disable="!agreed" @click="next" color="primary">Next</q-btn>
              </div>
            </div>
            <div v-if="index == 1">
              <device-helper v-on:done="next()"></device-helper>
            </div>
            <div v-if="index == 2">
              <p>Please choose which devices to use:</p>
              <device-picker :speaker="speakers" :microphone="microphone" :camera="camera" v-on:apply="applyDevices"
                v-on:cancel="back(2)"></device-picker>
            </div>

            <q-card v-if="index == 3">
              <q-card-section>
                <p>Please type the terminal's phone extension number:</p>
                <q-input outlined v-model="pin" label="Extension number (eg 12345)"
                  style="max-width:350px; margin: 0 auto; font-size:22px" required
                  :rules="[(val) => !!val || 'Field is required']" type="number">
                  <template v-slot:prepend>
                    <q-icon name="call" />
                  </template>
                </q-input>

                <p style="margin-top:10px;">And your full name:</p>
                <q-input outlined v-model="fullName" label="Full name"
                  style="max-width:350px; margin: 0 auto; font-size:22px" required
                  :rules="[(val) => !!val || 'Field is required']">
                  <template v-slot:prepend>
                    <q-icon name="account_box" />
                  </template>
                </q-input>
                <q-separator style="margin-top:20px" />
                <div style="margin-top: 10px" class="q-gutter-sm">
                  <q-btn flat @click="back()" color="primary">Back</q-btn>
                  <q-btn @click="joinToRoom" :loading="joining" color="primary"
                    :disable="!fullName || !pin">Next</q-btn>
                </div>
              </q-card-section>
            </q-card>

            <div v-if="index == 4">
              <p>
                You are ready to join! The call was accepted. Click next and
                wait for them to join...
              </p>
              <q-btn @click="join()" color="primary">Next</q-btn>
            </div>

            <q-dialog v-model="showError">
              <q-card>
                <q-separator />
                <q-card-section style="max-height: 50vh" class="scroll">
                  <p>{{ errorMessage }}</p>
                </q-card-section>
                <q-separator />
                <q-card-actions align="right">
                  <q-btn label="OK" color="primary" v-close-popup />
                </q-card-actions>
              </q-card>
            </q-dialog>

            <q-dialog v-model="showTerms">
              <q-card>
                <q-separator />
                <q-card-section>Terms of Use</q-card-section>
                <q-card-section style="max-height: 50vh" class="scroll">
                  <p>
                    I agree to indemnify, defend and hold harmless i3 Solutions
                    Inc., its affiliates, officers, directors, employees,
                    consultants, agents, suppliers, resellers and clients from
                    any and all third party claims, liability, damages and/or
                    costs (including, but not limited to, attorneys' fees)
                    arising from My use of the IBT Panacea InTouch feature, My
                    violation of this Agreement or the infringement or violation
                    by Me or any other user of My account, of any intellectual
                    property or other right of any person or entity or
                    applicable law.
                  </p>

                  <p>
                    To the maximum extent permitted by applicable law, in no
                    event will i3 Solutions Inc. or its affiliates, suppliers or
                    resellers be liable for any special, incidental, indirect,
                    exemplary or consequential damages whatsoever (including,
                    without limitation, damages for loss of business profits,
                    business interruption, loss of business information, or any
                    other pecuniary loss or damage) arising out of the use of or
                    inability to use the IBT Panacea InTouch feature or the
                    provision of or failure to provide technical or other
                    support services, whether arising in tort (including
                    negligence) contract or any other legal theory, even if i3
                    Solutions Inc., its affiliates, suppliers, resellers or
                    client have been advised of the possibility of such damages.
                    In any case, i3 Solutions Inc.’s, its affiliates',
                    suppliers' resellers' and clients maximum cumulative
                    liability and My exclusive remedy for any claims arising out
                    of or related to this agreement will be limited to the
                    amount actually paid by Me for the services (if any).
                  </p>
                </q-card-section>
                <q-separator />
                <q-card-actions align="right">
                  <q-btn label="OK" color="primary" v-close-popup />
                </q-card-actions>
              </q-card>
            </q-dialog>

          </div>
          <div v-if="!supported" style="text-align:center">
            <h5 style="margin: 0; padding-top:20px; padding-bottom:10px;">
              Browser not supported
            </h5>
            <p>
              Sorry, your browser does not support all the features required.
              Please try with a different browser.
            </p>
          </div>
        </q-page>
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import platform from "platform";
import DeviceHelper from "../components/DeviceHelper";
import DevicePicker from "../components/DevicePicker";
export default {
  components: {
    DeviceHelper,
    DevicePicker,
  },
  data() {
    return {
      index: 0,
      putik: null,
      wards: [],
      selectedWard: null,
      selectedRoom: null,
      joining: false,
      camera: undefined,
      speakers: undefined,
      microphone: undefined,
      pin: undefined,
      fullName: undefined,
      showError: false,
      supported: true,
      errorMessage: "",
      agreed: false,
      showTerms: false,
    }
  },

  props: {
    extension: String,
  },

  mounted() {
    this.pin = this.extension;
    var getUserMedia = (getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia);
    if (!getUserMedia && navigator.mediaDevices) {
      getUserMedia =
        navigator.mediaDevices.getUserMedia ||
        navigator.mediaDevices.webkitGetUserMedia ||
        navigator.mediaDevices.mozGetUserMedia ||
        navigator.mediaDevices.msGetUserMedia;
    }
    if (!getUserMedia) {
      this.supported = false;
    }
    if (platform.name == "Microsoft Edge") {
      this.supported = false;
    }
    if (process.env.NODE_ENV === "development") {
      this.pin = "10517";
      this.fullName = "test";
    }
  },

  methods: {
    next() {
      this.index++;
    },
    back(num) {
      this.index -= num ? num : 1;
    },
    selectWard(ward) {
      this.selectedWard = ward;
    },
    selectRoom(room) {
      this.selectedRoom = room;
    },
    selectBed(putik) {
      this.putik = putik;
      this.index = 3;
    },
    async getWards() { },
    async joinToRoom() {
      this.joining = true;
      try {
        const res = await this.$axios.get(
          // this.$openViduServer +
          "/get-session/" +
          this.pin +
          "/" +
          encodeURIComponent(this.fullName) +
          "/token"
        );
        this.token = res.data;
        this.next();
      } catch (e) {
        if (e.response && e.response.status == 404) {
          this.errorMessage =
            "We could not connect you with the target device because it was not found. Make sure that you entered the correct extension.";
          this.showError = true;
        } else if (e.response && e.response.status == 403) {
          this.errorMessage = "The call was declined by the user.";
          this.showError = true;
        } else if (e.response && e.response.status == 400) {
          this.errorMessage = "Destination is busy. Please try again later.";
          this.showError = true;
        } else {
          console.warn(e);
          this.errorMessage = "Destination did not respond. Try again later.";
          this.showError = true;
        }
      }
      this.joining = false;
    },
    async join() {
      console.log("joining...", this);
      await this.$router.push({
        name: "join",
        query: {
          id: this.token,
          camera: this.camera,
          microphone: this.microphone,
          speakers: this.speakers,
          extension: this.extension,
        },
      });
      // --- 8) Publish your stream ---
    },
    applyDevices(camera, speakers, microphone) {
      this.speakers = speakers;
      this.microphone = microphone;
      this.camera = camera;
      this.next();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#wizard {
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
}

#errors {
  font-size: 16px;
  color: red;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

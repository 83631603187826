import { createRouter, createWebHashHistory } from "vue-router";
import VideoConference from "@/views/VideoConference";
import Home from "./views/HomeView";

const router = createRouter({
  history: createWebHashHistory("/"),
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      props: (route) => ({
        extension: route.query.ext,
      }),
    },
    {
      path: "/join",
      name: "join",
      component: VideoConference,
      props: (route) => ({
        id: route.query.id,
        microphone: route.query.microphone,
        speakers: route.query.speakers,
        camera: route.query.camera,
      }),
    },
  ],
});

export default router;

import axios from "axios";
axios.defaults.timeout = 180000;

const axiosPlugin = {
  $axios: axios,
  $openViduServer: `${process.env.VUE_APP_DOMAIN}/`,
  $sleep: (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
};

export default axiosPlugin;

<template>
  <div class="q-pa-md q-gutter-sm">
    <p>{{ hardwareMessage }}</p>
    <q-circular-progress indeterminate size="90px" :thickness="0.2" color="lime" center-color="grey-8"
      track-color="transparent" class="q-ma-md" v-if="gettingDevices" />
    <div>
      <a v-if="!gettingDevices && showRetryDevices" target="_blank"
        href="https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=en">Get help on how to
        enable the permission for your browser</a>
    </div>
    <q-btn @click="getDevices()" v-if="!gettingDevices && showRetryDevices">Retry</q-btn>
    <q-btn @click="$emit('done')" color="primary" v-if="!gettingDevices && showRetryDevices">Continue without a
      camera</q-btn>
    <q-btn @click="$emit('done')" color="primary" v-if="!gettingDevices && !showRetryDevices">Next</q-btn>
    <div v-if="errors.length > 0" id="errors">{{ errors.join(" ,") }}</div>
  </div>
</template>

<script>
import { tryGetStream } from "../plugins/devicehelper";
export default {
  name: "device-helper",
  data() {
    return {
      hardwareMessage: "",
      hasCamera: false,
      hasMicrophone: false,
      showRetryDevices: false,
      errors: [],
      gettingDevices: false,
    }
  },
  async mounted() {
    await this.getDevices();
  },
  methods: {
    async getDevices() {
      this.gettingDevices = true;
      this.hardwareMessage = "Detecting devices...";

      if (
        (await this.isDeviceAllowed("microphone")) != 1 ||
        (await this.isDeviceAllowed("camera")) != 1
      ) {
        this.hardwareMessage =
          "Press allow in your browser's permission popup box...";
        if (
          await tryGetStream({
            audio: true,
            video: {
              width: { ideal: 320 },
              height: { ideal: 240 },
              frameRate: {
                ideal: 10,
                max: 15,
              },
            },
          })
        ) {
          this.$emit("done");
        } else {
          this.hasCamera = await tryGetStream({
            video: {
              width: { ideal: 320 },
              height: { ideal: 240 },
              frameRate: {
                ideal: 10,
                max: 15,
              },
            },
          });
          this.hasMicrophone = await tryGetStream({ audio: true });
          if (this.hasMicrophone) {
            if (await this.isDeviceAllowed("camera")) {
              this.hardwareMessage =
                "It appears that you do not have a camera installed. You can continue using only your microphone but others will not be able to see you.";
            } else {
              this.hardwareMessage =
                "You did not allow camera permission. You can continue using only your microphone but others will not be able to see you.";
            }
            this.showRetryDevices = true;
          } else {
            this.showRetryDevices = true;
            this.hardwareMessage =
              "Could not find any camera or microphone devices";
          }
        }
      } else {
        this.$emit("done");
      }
      this.gettingDevices = false;
    },
    async isDeviceAllowed(name) {
      if (
        window.navigator.permissions == undefined ||
        window.navigator.permissions.query == undefined
      ) {
        return 2;
      }
      try {
        let micStatus = await window.navigator.permissions.query({
          name,
        });
        switch (micStatus.state) {
          case "granted":
            return 1;
          case "prompt":
            return 2;
          default:
            return false;
        }
      } catch (err) {
        console.error(err);
        return false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  methods: {
    notify: () => {
      console.log("notify quasar", this.$q)
      this.$q.notify("Running on Quasar v");
    },
  },
}
</script>
<style>
body {
  background: #f1f1f1;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  background: #f1f1f1;
}
</style>

async function tryGetStream(what) {
    try {
        console.log('tryGetStream: ' + JSON.stringify(what))
        let stream = await window.navigator.mediaDevices.getUserMedia(what);
            stream.getTracks().forEach(function(track) {
            track.stop();
        });
        return true;
    } catch (e) {
        console.log('tryGetStream error')
        console.error(e);
    }
    return false;
}
export { tryGetStream }
import * as Vue from "vue";
import { OpenVidu } from "openvidu-browser";
import Quasar from 'quasar/src/vue-plugin.js';;
import App from "./App.vue";
import router from "./router";
import axiosPlugin from "./plugins/axios";
import "./styles/quasar.sass";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";

console.table(process.env);

const app = Vue.createApp(App);

app.config.globalProperties.$q = Vue.getCurrentInstance();
app.config.globalProperties.$axios = axiosPlugin.$axios;
app.config.globalProperties.$openViduServer = axiosPlugin.$openViduServer;
app.config.globalProperties.$sleep = axiosPlugin.$sleep;
app.config.globalProperties.$openVidu = OpenVidu;

app.use(router);
app.use(Quasar, {
  config: {},
  components: {
    /* not needed if importStrategy is not 'manual' */
  },
  directives: {
    /* not needed if importStrategy is not 'manual' */
  },
  plugins: {},
});

app.mount("#app");

<template>
  <div style="height: 100%;">
    <div class="conference" id="conference" style="display: flex; height: 100%">
      <div class="tools" id="tools" v-if="inCall">
        <div class="q-gutter-sm">
          <q-btn color="red-6" stack @click="disconnect" icon="call_end">Hang up</q-btn>
          <q-btn :color="!muted ? 'primary' : 'red-6'" style="width:100px" stack
            @click="toggleMute"
            :icon="muted ? 'mic_off' : 'mic'">{{ muted ? "Unmute" : "Mute" }}</q-btn>
          <q-btn color="primary" stack @click="devicesModal = true" icon="settings"
            v-if="notCefSharp()">Devices</q-btn>
        </div>
      </div>
      <div id="video">
        <div id="videos"></div>
      </div>
      <div class="tools" v-if="inCall && timeLeft > 0">
        <div style="margin: 0 auto; color: white;font-size:16px" class="q-gutter-sm">
          <span>
            Your meeting will end automatically in
            <span style="font-size:20px">{{ Math.floor(timeLeft / 60) }}</span>
            {{ Math.floor(timeLeft / 60) == 1 ? "min" : "mins" }} and
            <span style="font-size:20px">{{ Math.floor(timeLeft % 60) }}</span>
            {{ Math.floor(timeLeft % 60) == 1 ? "sec" : "secs" }}. If you need
            to reconnect you may start a new meeting right after.
          </span>
        </div>
      </div>
    </div>
    <q-dialog v-model="errorModal" persistent>
      <q-card>
        <q-card-section>
          <div class="text-h6">Error</div>
        </q-card-section>
        <q-separator />
        <q-card-section style="max-height: 50vh" class="scroll">
          <p>We could not connect you with the target device.</p>
          <p>
            If you visited this link from your browser's history then the
            session must have expired and you need to click cancel and initiate
            a call again.
          </p>
          <p>
            Otherwise, Click Retry and if the issue persists, click cancel and
            try to connect again.
          </p>
        </q-card-section>
        <q-separator />
        <q-card-actions align="right">
          <q-btn flat label="Cancel" v-close-popup @click="
            $router.push({
              name: 'home',
              query: {
                extension: extension,
              },
            })
            " />
          <q-btn label="Retry" color="primary" v-close-popup @click="connect(id)" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="devicesModal">
      <device-picker :speaker="dspeakers" :microphone="dmicrophone" :camera="dcamera"
        v-on:apply="applyDevices" :showCancel="true"></device-picker>
    </q-dialog>
  </div>
</template>

<script>
import { OpenVidu } from "openvidu-browser";
import DevicePicker from "../components/DevicePicker";
export default {
  components: {
    DevicePicker,
  },
  data: () => ({
    interval: undefined,
    timeLeft: 0,
    OV: undefined,
    session: undefined,
    publisher: undefined,
    errorModal: false,
    inCall: false,
    muted: false,
    devicesModal: false,
    dspeakers: undefined,
    dmicrophone: undefined,
    dcamera: undefined,
    sessions: [],
    volume: 100,
    ownerTimeout: undefined
  }),
  props: {
    id: undefined,
    speakers: undefined,
    microphone: undefined,
    camera: undefined,
    extension: undefined,
  },
  async mounted() {
    this.ownerTimeout = setTimeout(() => {
      this.disconnect();
    }, process.env.VUE_APP_JOIN_TIMEOUT * 1000);
    window.addEventListener("resize", this.updateVideosLayout);
    this.dspeakers = this.speakers;
    this.dmicrophone = this.microphone;
    this.dcamera = this.camera;
    if (this.id) {
      await this.connect(this.id);
    } else if (process.env.NODE_ENV === "development") {
      this.inCall = true;
    }
  },

  destroyed() {
    window.removeEventListener("resize", this.updateVideosLayout);
  },

  watch: {
    volume: {
      immediate: true,
      handler() {
        this.setVolume();
      },
    },
  },

  methods: {
    async kick(sessionid, id) {
      try {
        await this.$axios.delete(
          "sessions/" + sessionid + "/connection/" + id
        );
      } catch {
        //
      }
    },
    setVolume() {
      if (!this.$el) {
        return;
      }
      for (const vid of this.$el.querySelectorAll("video")) {
        vid.volume = this.volume / 100.0;
      }
    },
    async handleTimeOut(stream) {
      if (stream) {
        const data = JSON.parse(stream.connection.data);
        if (data.name === "__OWNER__") {
          clearTimeout(this.ownerTimeout);
          const start = new Date(data.start);
          console.log("starting count down", start);
          const now = new Date();
          const diff = Math.abs(now - start);
          const totalSeconds = diff / 1000;
          this.timeLeft = process.env.VUE_APP_MAX_CALL_DURATION;
          if (this.timeLeft > 0) {
            this.timeLeft = this.timeLeft - totalSeconds;
            if (this.interval) {
              clearTimeout(this.interval);
            }
            const $this = this;
            this.interval = setInterval(function () {
              $this.$nextTick(function () {
                this.timeLeft--;
                if (this.timeLeft <= 0) {
                  clearTimeout(this.interval);
                  this.disconnect();
                }
              });
            }, 1000);
          }
        }
      }
      await this.$sleep(10);
      this.updateVideosLayout();
    },
    async connect(token) {
      OpenVidu.prototype.checkSystemRequirements = () => 1;
      this.OV = new OpenVidu();
      //this.OV.enableProdMode();
      this.OV.setAdvancedConfiguration({
        publisherSpeakingEventsOptions: {
          interval: 500, // Frequency of the polling of audio streams in ms (default 100)
          threshold: -50, // Threshold volume in dB (default -50)
        },
      });
      this.session = this.OV.initSession();
      var sess = this.session;
      const $this = this;
      this.session.on("streamCreated", function (event) {
        console.log("session streamCreated", event);
        const subscriber = sess.subscribe(event.stream, "videos");
        $this.sessions.push({ stream: event.stream, subscriber });
        subscriber.on("videoElementCreated", function (event) {
          const videoElement = event.element;
          videoElement.style.position = "relative";
          var div = document.createElement("div");
          div.style.position = "absolute";
          const parent = videoElement.parentNode;
          videoElement.parentNode.removeChild(videoElement);
          div.appendChild(videoElement);
          JSON.parse(subscriber.stream.connection.data);

          var a = document.createElement("a");
          a.classList.add("kick");
          a.style.position = "absolute";
          a.href = "javascript:void(0);";
          a.innerHTML = "Dismiss";
          a.addEventListener("click", function () {
            console.log(event);
            $this.kick(
              event.target.stream.connection.session.sessionId,
              event.target.stream.connection.connectionId
            );
          });
          div.appendChild(a);

          parent.appendChild(div);
          $this.updateVideosLayout();
          $this.setVolume();
        });
        subscriber.on("videoElementDestroyed", function () {
          console.log("subscriber videoElementDestroyed");
          $this.updateVideosLayout();
          $this.setVolume();
        });
        $this.handleTimeOut(subscriber.stream);
      });
      this.session.on("streamDestroyed", function (event) {
        console.log("session streamDestroyed", event);
        const str = $this.sessions.find((a) => a.stream == event.stream);

        if (str) {
          $this.sessions.splice($this.sessions.indexOf(str), 1);
          const data = JSON.parse(str.subscriber.stream.connection.data);
          if (data.name === "__OWNER__") {
            $this.disconnect();
          }
          if ($this.sessions.length === 0 && event.reason !== "unpublish") {
            $this.disconnect();
          }
        }
      });
      this.session.on("connectionDestroyed", function (event) {
        console.log("connectionDestroyed");
        if (event.connection === sess.connection) {
          $this.disconnect();
        }
      });

      try {
        this.publisher = this.OV.initPublisher("videos", {
          audioSource: this.dmicrophone, // The source of audio. If undefined default audio input
          videoSource: this.dcamera, // The source of video. If undefined default video input
          publishAudio: true, // Whether to start publishing with your audio unmuted or not
          publishVideo: true, // Whether to start publishing with your video enabled or not
          resolution: "320x240", // The resolution of your video
          frameRate: 10, // The frame rate of your video
          insertMode: "PREPEND", // How the video is inserted in target element 'video-container'
          mirror: false, // Whether to mirror your local video or not
        });
        this.publisher.on("videoElementCreated", function (event) {
          console.log("publisher videoElementCreated", event);
          const videoElement = event.element;
          videoElement.style.position = "relative";
          var div = document.createElement("div");
          div.style.position = "absolute";
          const parent = videoElement.parentNode;
          videoElement.parentNode.removeChild(videoElement);
          div.appendChild(videoElement);
					parent.appendChild(div);
					const postMessage = window.chrome?.webview?.postMessage;
					if (postMessage) {
						console.log("found WebView2 postMessage");
						postMessage({ action: "Playing", target: "panacea" });
					}
        });
        this.publisher.on("videoElementDestroyed", function () {
          console.log("publisher videoElementDestroyed");
        });
        this.publisher.on("streamCreated", function () {
          console.log("publisher streamCreated");
          $this.handleTimeOut($this.session.connection.stream);
        });
        this.publisher.on("streamDestroyed", function (event) {
          console.log("publisher streamDestroyed", event);
          if (event.reason == "forceDisconnectByServer") {
            $this.disconnect();
          }
        });
        await this.session.connect(token);
        await this.session.publish(this.publisher);
        this.inCall = true;
      } catch (e) {
        console.error(e);
        if (process.env.NODE_ENV === "production") {
          this.errorModal = true;
        }
      }
    },

    applyDevices(camera, speakers, microphone) {
      console.log("apply", speakers);
      this.dspeakers = speakers;
      this.dmicrophone = microphone;
      this.dcamera = camera;
      this.changeDevices();
    },
    changeDevices() {
      const $this = this;
      this.session.unpublish(this.publisher);
      this.publisher = this.OV.initPublisher("videos", {
        audioSource: this.dmicrophone, // The source of audio. If undefined default audio input
        videoSource: this.dcamera, // The source of video. If undefined default video input
        publishAudio: true, // Whether to start publishing with your audio unmuted or not
        publishVideo: true, // Whether to start publishing with your video enabled or not
        resolution: "320x240", // The resolution of your video
        frameRate: 10, // The frame rate of your video
        insertMode: "PREPEND", // How the video is inserted in target element 'video-container'
        mirror: false, // Whether to mirror your local video or not
      });
      this.publisher.on("videoElementCreated", function (event) {
        const videoElement = event.element;
        videoElement.style.position = "relative";
        var div = document.createElement("div");
        div.style.position = "absolute";
        const parent = videoElement.parentNode;
        videoElement.parentNode.removeChild(videoElement);
        div.appendChild(videoElement);
        parent.appendChild(div);
        $this.updateVideosLayout();
      });
      this.publisher.on("videoElementDestroyed", function () {
        $this.updateVideosLayout();
      });
      this.publisher.on("streamCreated", function () {
        $this.handleTimeOut($this.session.connection.stream);
      });
      this.publisher.on("streamDestroyed", function (event) {
        console.log("destroyed", event);
      });

      this.session.publish(this.publisher);
    },
    async disconnect() {
      if (this.session) {
        this.session.disconnect();
      }
      if (this.interval) {
        clearTimeout(this.interval);
      }
      if (window.CefSharp) {
        console.log("found CEF");
        await window.CefSharp.BindObjectAsync("panacea", "panacea");
        if (window.panacea) {
          console.log("found Panacea");
          window.panacea.close();
        }
			}
			const postMessage = window.chrome?.webview?.postMessage;
			if (postMessage) {
				console.log("found WebView2");
				postMessage({ action: "CloseWindow", target: "panacea" });
			}

      this.$router.push({
        name: "home",
        query: {
          extension: this.extension,
        },
      });
    },
    notCefSharp() {
      return window.CefSharp === undefined;
    },
    toggleMute() {
      this.muted = !this.muted;
      this.publisher.publishAudio(!this.muted);
    },
    findBestLayout(width, height, boxes) {
      const isLandscape = width > height * 1.2;
      switch (boxes.length) {
        case 1:
          return [1, 1];
        case 2:
          return isLandscape ? [1, 2] : [2, 1];
        case 3:
          return isLandscape ? [2, 2] : [3, 1];
        default:
          return [2, 2];
      }
    },
    async updateVideosLayout() {
      const conference = this.$el.querySelector("#videos");
      const videoDivs = this.$el.querySelectorAll("#videos > div");
      videoDivs.forEach((d) => {
        if (d.querySelectorAll("video").length === 0) {
          conference.removeChild(d);
        }
      });
      const width = conference.offsetWidth;
      const height = conference.offsetHeight;
      const videos = this.$el.querySelectorAll("#videos video");
      if (videos) {
        const boxes = [...videos].map((v) => ({
          w: v.videoWidth,
          h: v.videoHeight,
          v,
        }));
        const zeroSize = boxes.filter((b) => b.w == 0 || b.h == 0);
        if (zeroSize.length > 0) {
          zeroSize.forEach((b) => {
            if (b.v.parentElement)
              b.v.parentElement.style.visibility = "hidden";
          });
          await this.$sleep(100);
          await this.updateVideosLayout();
          return;
        }

        for (var v of videos) {
          if (v.parentElement) {
            v.parentElement.style.visibility = "visible";
          }
          try {
            await v.setSinkId(this.dspeakers);
          } catch {
            //
          }
        }
        const m = this.findBestLayout(width, height, boxes);

        for (var i = 0; i < m[0]; i++) {
          for (var c = 0; c < m[1]; c++) {
            const v = videos[i * m[1] + c];
            if (v) {
              const h = height / m[0];
              const w = width / m[1];
              if (v.parentElement) {
                v.parentElement.style.height = h + "px";
                v.parentElement.style.width = w + "px";
                v.parentElement.style.left = w * c + "px";
                v.parentElement.style.top = (i * height) / m[0] + "px";
                const a = v.parentElement.getElementsByTagName("a");
                if (a && a.length > 0) {
                  const actualVideoHeight =
                    Math.abs(h / v.videoHeight) < Math.abs(w / v.videoWidth)
                      ? h
                      : (w / v.videoWidth) * v.videoHeight;

                  let actualVideoWidth =
                    Math.abs(h / v.videoHeight) < Math.abs(w / v.videoWidth)
                      ? (h / v.videoHeight) * v.videoWidth
                      : w;
                  if (actualVideoWidth < 0) {
                    actualVideoWidth = 0;
                  }
                  let bottom = Math.abs(h - actualVideoHeight);

                  let left = Math.abs(w - actualVideoWidth);

                  a[0].style.bottom = bottom / 2 + "px";
                  a[0].style.left = left / 2 + "px";
                  a[0].style.width = actualVideoWidth + "px";
                }
              }
            }
          }
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#video {
  flex: 1;
  background: black;
}

#videos {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  height: 100%;
  background: black;
}

.conference {
  height: 100%;
  display: flex;
  flex-direction: column;
}

video {
  background: black;
  position: absolute;
  width: 100%;
  height: 100%;
}

.tools {
  padding: 5px;
  background: #222;
  display: flex;
  justify-content: center;
}

.kick {
  display: block;
  background: rgba(0, 0, 0, 0.4);
  padding: 5px 15px;
  left: 0;
  bottom: 0;
  width: calc(100%);
  color: white;
  font-size: 18px;
}
</style>

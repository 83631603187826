<template>
  <q-card style="text-align:left">
    <q-card-section>
      <div class="text-h6">Devices</div>
    </q-card-section>
    <q-separator />
    <q-card-section style="max-height: 50vh" class="scroll">
      Camera:
      <div class="q-gutter-sm">
        <q-radio v-for="d of cameras" v-bind:key="d.deviceId" v-model="selectedCamera" :val="d.deviceId"
          :label="d.label" />
      </div>Speakers:
      <div class="q-gutter-sm">
        <q-radio v-for="d of speakers" v-bind:key="d.deviceId" v-model="selectedSpeaker" :val="d.deviceId"
          :label="d.label" />
      </div>Microphone:
      <div class="q-gutter-sm">
        <q-radio v-for="d of microphones" v-bind:key="d.deviceId" v-model="selectedMicrophone" :val="d.deviceId"
          :label="d.label" />
      </div>
    </q-card-section>
    <q-separator />
    <q-card-actions align="right">
      <q-btn flat :label="showCancel ? 'Cancel' : 'Back'" v-close-popup @click="$emit('cancel')" />
      <q-btn :label="showCancel ? 'Apply' : 'Next'" color="primary" v-close-popup
        @click="$emit('apply', selectedCamera, selectedSpeaker, selectedMicrophone)" />
    </q-card-actions>
  </q-card>
</template>

<script>
export default {
  props: {
    showCancel: {
      type: Boolean,
      default: true
    },
    camera: undefined,
    speaker: undefined,
    microphone: undefined
  },
  data: () => ({
    selectedCamera: undefined,
    selectedMicrophone: undefined,
    selectedSpeaker: undefined,
    cameras: [],
    microphones: [],
    speakers: []
  }),
  async mounted() {
    var devices = []
    try {
      var videoDevices = await this.getDevices({ video: true });
      devices = videoDevices;
    } catch (err) {
      console.log(err);
      try {
        var audioDevices = await this.getDevices({ audio: true });
        devices = audioDevices;
      } catch (err) {
        console.log(err);
      }
    }
    if (!this.selectedCamera) this.selectedCamera = this.camera;
    if (!this.selectedMicrophone) this.selectedMicrophone = this.microphone;
    if (!this.selectedSpeaker) this.selectedSpeaker = this.speaker;
    for (var device of devices) {
      if (device.kind == "videoinput") {
        this.cameras.push(device);
      } else if (device.kind == "audioinput") {
        this.microphones.push(device);
      } else if (device.kind == "audiooutput") {
        this.speakers.push(device);
      }
      if (this.selectedCamera === undefined && this.cameras.length > 0) {
        this.selectedCamera = this.cameras[0].deviceId;
      }
      if (
        this.selectedMicrophone === undefined &&
        this.microphones.length > 0
      ) {
        this.selectedMicrophone = this.microphones[0].deviceId;
      }
      if (this.selectedSpeaker === undefined && this.speakers.length > 0) {
        this.selectedSpeaker = this.speakers[0].deviceId;
      }
    }
  },
  methods: {
    async getDevices(devicesToAsk) {
      let devices = await navigator.mediaDevices.enumerateDevices();
      try {
        let stream = await window.navigator.mediaDevices.getUserMedia(devicesToAsk);
        devices = await navigator.mediaDevices.enumerateDevices();
        stream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
      catch (err) {
        console.log("ERROR!!!")
        throw err;
      }
      return devices
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
